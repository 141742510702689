import Vue from 'vue'
import Vuesax from 'vuesax'
import AsyncComputed from 'vue-async-computed'

import App from './App.vue'

import 'vuesax/dist/vuesax.css' //Vuesax styles
import 'material-icons/iconfont/material-icons.css';
// Vuex Store
import store from './store/store'
// Theme Configurations
import '../themeConfig.js'
// Perfectscrollbar
// import PerfectScrollbar from "vue2-perfect-scrollbar";
// import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
// Vue.use(PerfectScrollbar);

import Vuebar from 'vuebar';
Vue.use(Vuebar);
// Theme Configurations
import 'prismjs'
import 'prismjs/themes/prism.css'
import VsPrism from './components/prism/VsPrism.vue';
Vue.component(VsPrism.name, VsPrism);
import VsSidebarGroup from './components/vs-sidebar-group/Sidebar-Group.vue';
Vue.component(VsSidebarGroup.name, VsSidebarGroup);
// i18n
import i18n from './i18n/i18n.js'
// Vue Router
import router from './router'

/*import { Stats } from "./services/Arena.js";
const st = new Stats;*/

var numeral = require("numeral");
numeral.locale('br');
Vue.filter("formatInteger", function (value) {
    return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});


Vue.config.productionTip = false

Vue.use(Vuesax)
Vue.use(AsyncComputed)

new Vue({
    data:
    {
        AppData: {}
    },
   /* created() {
        st.Get().then((resp) => {
            alert("pegou dados created");            
            alert(resp.countries);

            alert("alterou dados geral");
            this.AppData = resp;
        }
            , (err) => alert(err));  
    },*/
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
import '@/assets/scss/style.scss'
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
    mode: 'hash',
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        }
    },
    base: process.env.BASE_URL,
    routes: [
        // ======================
        // Blank Layout
        // ======================    
        {
            path: '',
            //component: () => import('./layout/full/HomeContainer.vue'),
            component: () => import('./layout/blank/Blank.vue'),
            // ======================
            // Theme routes / pages
            // ======================
            children: [{
                path: '/',
                redirect: '/home'
            }, {
                path: '/login',
                name: 'Login',
                index: 1,
                component: () => import('./views/authentication/Login.vue')
            }, {
                path: '/error404',
                name: 'Error 404',
                index: 2,
                component: () => import('./views/authentication/Error404.vue')
            }, {
                path: '/register',
                name: 'Register',
                index: 3,
                component: () => import('./views/authentication/Register.vue')
            }]
        },
        {
            // ======================
            // Home (antes login depois vira full)
            // ======================
            path: '',
            component: () => import('./layout/full/MainContainer.vue'),
            // ======================
            // Theme routes / pages
            // ======================
            children: [{
                path: '/teste',
                name: 'Teste',
                index: 1,
                component: () => import('./views/StarterPage.vue'),
            },
            ]
        },
        {
            // ======================
            // Home (antes login depois vira full)
            // ======================
            path: '',
            component: () => import('./layout/full/HomeContainer.vue'),
            // ======================
            // Theme routes / pages
            // ======================
            children: [{                
                path: '/home',
                name: 'Home',
                index: 1,
                component: () => import('./views/home/principal/Home.vue'),                
            },           
            ]
        },
        {
            // ======================
            // Full Layout
            // ======================
            path: '',
            component: () => import('./layout/full/SurveyContainer.vue'),
            
            // ======================
            // Theme routes / pages
            // ======================
            children: [
                {
                path: '/estadios',
                name: 'Estadios',
                index: 1,                
                component: () => import('./views/Surveys/Page/Stadiums.vue')
                },
                {
                    path: '/estadios/det/:id',
                    name: 'Ficha do Estadio',
                    index: 1,
                    component: () => import('./views/Surveys/Page/DetStadium.vue')
                },
                {
                    path: '/surveys',
                    name: 'Surveys',
                    index: 2,
                    component: () => import('./views/Surveys/Page/Surveys.vue'),
                },
                {
                    path: '/surveys/struct2/:chap',
                    name: 'Detalhamento Survey',
                    index: 2.1,
                    component: () => import('./views/Surveys/Page/SurveyStruct2.vue'),
                },
                {
                    path: '/surveys/scoredet/:id',
                    name: 'Score Survey',
                    index: 2.2,
                    component: () => import('./views/Surveys/Page/SurveyScore.vue'),
                },
                {
                path: '/ranking',
                name: 'Ranking',
                index: 3,
                    component: () => import('./views/Surveys/Page/Ranking.vue')
                },
                {
                    path: '/ranking/geral',
                    name: 'Ranking Geral',
                    index: 3.1,
                    component: () => import('./views/Surveys/Page/RankingGeral.vue')
                },
                               
            ]
        },
        {
            // ======================
            // Survey struct
            // ======================
            path: '',
            component: () => import('./layout/full/SurveyStructContainer.vue'),

            // ======================
            // Theme routes / pages
            // ======================
            children: [                
                {
                    path: '/surveys/struct2/:chap/:subchap',
                    name: 'Detalhamento Survey',
                    index: 4,
                    component: () => import('./views/Surveys/Page/SurveyStruct2.vue'),
                }
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/Error404'
        }
    ]
})

import NProgress from 'nprogress';

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    
    if (to.name) {
        // Start the route progress bar.
        NProgress.start()
    }
    next()
})

router.beforeEach((to, from, next) => {
    if (to.name != "Home")
    {        
        if (to.name != "Login")
        {                        
            if (!router.app.$store.state.isAuthenticated)
            {                                                
                router.app.$store.commit('SET_NextRoute',to.path);              
                next('/login')
            }
            else
            {                
                next();
            } 
        }
        else
            next();
    }
    else
        next();
})

router.afterEach(() => {
    // Complete the animation of the route progress bar.
    NProgress.done()
})
export default router
import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export default new VueI18n({
	locale: 'pt', // set default locale
	fallbackLocale: 'en',
	silentTranslationWarn: true,
	messages: {
        en: {
            StadiumCode: 'Code',
            Address: 'Address',
            City: 'City',
            Country: 'Country',
            Capacity: 'Capacidade',
            Ranking: 'Ranking',
            Geral: 'General',
            Arquitetura: 'Arquitecture',
            Gramado: 'Grass',
            Topografia: 'Topography',
            Ilumination: 'Ilumination',
            Chapters: 'Chapters',
            Columns: 'Columns',
            Filter: 'Filter',
            SurveysByCountry: 'Surveys by country',            
            Countries: 'Countries',
            Stadiums: 'Stadiums',
            Welcome: 'Bem vindo ao sistema Arena V2.',
            RememberMe: 'Remember me?',
            ForgetPassword: 'Forgot Password?',
            AskAccess: 'Ask for access',
            NoAccount: 'No account?',
			Starterkit: 'Starterkit',
			Dashboards: 'Dashboards',
			Classic: 'Classic ',
			Analytical: 'Analytical ',
			Ecommerce: 'Ecommerce ',
			General: 'General ',
			Apps: 'Apps',
			Chats: 'Chats',
			Calendar: 'Calendar',
			ContactList: 'Contact List',
			ContactGrid: 'Contact Grid',
			Email: 'Email',
			Todo: 'Todo',
			UiElements: 'Ui Elements',
			VuesaxComponents: 'Vuesax Components',
			Alert: 'Alert',
			Avatar: 'Avatar',
			Breadcrumb: 'Breadcrumb',
			Buttons: 'Buttons',
			Cards: 'Cards',
			Chips: 'Chips',
			Collapse: 'Collapse',
			Dialog: 'Dialog',
			Divider: 'Divider',
			Dropdown: 'Dropdown',
			List: 'List',
			Loading: 'Loading',
			Navbar: 'Navbar',
			Notification: 'Notification',
			Numberinput: 'Number input',
			Pagination: 'Pagination',
			Popup: 'Popup',
			Progress: 'Progress',
			Sidebar: 'Sidebar',
			Slider: 'Slider',
			Tabs: 'Tabs',
			Tooltip: 'Tooltip',
			Forms: 'Forms',
			FormElements: 'Form Elements',
			Checkboxes: 'Checkboxes',
			Datepicker: 'Datepicker',
			Input: 'Input',
			Radio: 'Radio',
			Select: 'Select',
			Switch: 'Switch',
			Textarea: 'Textarea',
			Upload: 'Upload',
			FormLayouts: 'Form Layouts',
			FormAction: 'Form Action',
			FormBasic: 'Form Basic',
			FormHorizontal: 'Form Horizontal',
			FormWizard: 'Form Wizard',
			DragnDrop: 'Drag n Drop',
			TableExamples: 'Table Examples',
			Basictable: 'Basic table',
			Editabletable: 'Editable table',
			ExpandDatatable: 'Expand Datatable',
			Filtertable: 'Filter table',
			Miscellaneoustable: 'Miscellaneous table',
			Paginationtable: 'Pagination table',
			Selectedtable: 'Selected table',
			SSTtable: 'SST table',
			Charts: 'Charts',
			ApexCharts: 'ApexCharts',
			ChartJs: 'ChartJs',
			Icons: 'Icons',
			MaterialIcons: 'Material Icons',
			ThemifyIcons: 'Themify Icons',
			ExtraComponents: 'Extra Components',
			Carousel: 'Carousel',
			Widgets: 'Widgets',
			AppWidgets: 'App Widgets',
			DataWidgets: 'Data Widgets'
		},
        pt: {
            StadiumCode: 'Código',
            Address: 'Endereço',
            City: 'Cidade',
            Country: 'País',
            Capacity: 'Capacidade',
            Ranking: 'Ranking',
            Geral: 'Geral',
            Arquitetura: 'Arquitetura',
            Gramado: 'Gramado',
            Topografia: 'Topografia',
            Ilumination:'Iluminação',
            Chapters: 'Capítulos',
            Columns: 'Colunas',
            Filter: 'Filtros',
            SurveysByCountry: 'Estudos por país',
            Countries: 'Países',
            Stadiums: 'Estádios',
            Welcome: 'Bem vindo ao sistema Arena V2.',
            RememberMe: 'Lembrar de me?',
            ForgetPassword: 'Esqueceu a senha?',
            AskAccess: ' Solicitar acesso',
            NoAccount: 'Não tem conta?',
            Starterkit: 'Hit de inicio',
            Dashboards: 'Dashboards',
            Classic: 'Classic ',
            Analytical: 'Analytical ',
            Ecommerce: 'Ecommerce ',
            General: 'General ',
            Apps: 'Apps',
            Chats: 'Chats',
            Calendar: 'Calendar',
            ContactList: 'Contact List',
            ContactGrid: 'Contact Grid',
            Email: 'Email',
            Todo: 'Todo',
            UiElements: 'Ui Elements',
            VuesaxComponents: 'Vuesax Components',
            Alert: 'Alert',
            Avatar: 'Avatar',
            Breadcrumb: 'Breadcrumb',
            Buttons: 'Buttons',
            Cards: 'Cards',
            Chips: 'Chips',
            Collapse: 'Collapse',
            Dialog: 'Dialog',
            Divider: 'Divider',
            Dropdown: 'Dropdown',
            List: 'List',
            Loading: 'Loading',
            Navbar: 'Navbar',
            Notification: 'Notification',
            Numberinput: 'Number input',
            Pagination: 'Pagination',
            Popup: 'Popup',
            Progress: 'Progress',
            Sidebar: 'Sidebar',
            Slider: 'Slider',
            Tabs: 'Tabs',
            Tooltip: 'Tooltip',
            Forms: 'Forms',
            FormElements: 'Form Elements',
            Checkboxes: 'Checkboxes',
            Datepicker: 'Datepicker',
            Input: 'Input',
            Radio: 'Radio',
            Select: 'Select',
            Switch: 'Switch',
            Textarea: 'Textarea',
            Upload: 'Upload',
            FormLayouts: 'Form Layouts',
            FormAction: 'Form Action',
            FormBasic: 'Form Basic',
            FormHorizontal: 'Form Horizontal',
            FormWizard: 'Form Wizard',
            DragnDrop: 'Drag n Drop',
            TableExamples: 'Table Examples',
            Basictable: 'Basic table',
            Editabletable: 'Editable table',
            ExpandDatatable: 'Expand Datatable',
            Filtertable: 'Filter table',
            Miscellaneoustable: 'Miscellaneous table',
            Paginationtable: 'Pagination table',
            Selectedtable: 'Selected table',
            SSTtable: 'SST table',
            Charts: 'Charts',
            ApexCharts: 'ApexCharts',
            ChartJs: 'ChartJs',
            Icons: 'Icons',
            MaterialIcons: 'Material Icons',
            ThemifyIcons: 'Themify Icons',
            ExtraComponents: 'Extra Components',
            Carousel: 'Carousel',
            Widgets: 'Widgets',
            AppWidgets: 'App Widgets',
            DataWidgets: 'Data Widgets'
        },
        es: {  
            Ranking: 'Ranking',
            Geral: 'General',
            Arquitetura: 'Arquitetura',
            Gramado: 'Cesped',
            Topografia: 'Topografia',
            Ilumination: 'Iluminación',
            Chapters: 'Capítulos',
            Filter: 'Colunas',
            SurveysByCountry: 'Surveys por pais',
            Stadiums: 'Estadios',
            Countries: 'Paises',
            Starterkit: 'Hit de inicio',
            Dashboards: 'Dashboards',
            Classic: 'Classic ',
            Analytical: 'Analytical ',
            Ecommerce: 'Ecommerce ',
            General: 'General ',
            Apps: 'Apps',
            Chats: 'Chats',
            Calendar: 'Calendar',
            ContactList: 'Contact List',
            ContactGrid: 'Contact Grid',
            Email: 'Email',
            Todo: 'Todo',
            UiElements: 'Ui Elements',
            VuesaxComponents: 'Vuesax Components',
            Alert: 'Alert',
            Avatar: 'Avatar',
            Breadcrumb: 'Breadcrumb',
            Buttons: 'Buttons',
            Cards: 'Cards',
            Chips: 'Chips',
            Collapse: 'Collapse',
            Dialog: 'Dialog',
            Divider: 'Divider',
            Dropdown: 'Dropdown',
            List: 'List',
            Loading: 'Loading',
            Navbar: 'Navbar',
            Notification: 'Notification',
            Numberinput: 'Number input',
            Pagination: 'Pagination',
            Popup: 'Popup',
            Progress: 'Progress',
            Sidebar: 'Sidebar',
            Slider: 'Slider',
            Tabs: 'Tabs',
            Tooltip: 'Tooltip',
            Forms: 'Forms',
            FormElements: 'Form Elements',
            Checkboxes: 'Checkboxes',
            Datepicker: 'Datepicker',
            Input: 'Input',
            Radio: 'Radio',
            Select: 'Select',
            Switch: 'Switch',
            Textarea: 'Textarea',
            Upload: 'Upload',
            FormLayouts: 'Form Layouts',
            FormAction: 'Form Action',
            FormBasic: 'Form Basic',
            FormHorizontal: 'Form Horizontal',
            FormWizard: 'Form Wizard',
            DragnDrop: 'Drag n Drop',
            TableExamples: 'Table Examples',
            Basictable: 'Basic table',
            Editabletable: 'Editable table',
            ExpandDatatable: 'Expand Datatable',
            Filtertable: 'Filter table',
            Miscellaneoustable: 'Miscellaneous table',
            Paginationtable: 'Pagination table',
            Selectedtable: 'Selected table',
            SSTtable: 'SST table',
            Charts: 'Charts',
            ApexCharts: 'ApexCharts',
            ChartJs: 'ChartJs',
            Icons: 'Icons',
            MaterialIcons: 'Material Icons',
            ThemifyIcons: 'Themify Icons',
            ExtraComponents: 'Extra Components',
            Carousel: 'Carousel',
            Widgets: 'Widgets',
            AppWidgets: 'App Widgets',
            DataWidgets: 'Data Widgets'
        },
        fr: {
            Filter: 'Chapitres',
            SurveysByCountry: 'Enquêtes par pays',
            Stadiums: 'Stades',
            Countries: 'Pays',
			Starterkit: 'Kit de démarrage',
			Dashboards: 'Tableaux de bord',
			Classic: 'Tableau de bord classique',
			Analytical: 'Tableau de bord analytique',
			Ecommerce: 'Commerce électronique',
			General: 'Tableau de bord général',
			Apps: 'applications',
			Chats: 'Chats',
			Calendar: 'Calendrier',
			ContactList: 'Liste de contacts',
			ContactGrid: 'ContactGrid',
			Email: 'Email',
			Todo: 'Faire',
			UiElements: 'UiElements',
			VuesaxComponents: 'VuesaxComponents',
			Alert: 'Alerte',
			Avatar: 'Avatar',
			Breadcrumb: 'Miette de pain',
			Buttons: 'Boutons',
			Cards: 'Cartes',
			Chips: 'chips',
			Collapse: 'Effondrer',
			Dialog: 'Dialogue',
			Divider: 'Diviseur',
			Dropdown: 'Menu déroulant',
			List: 'liste',
			Loading: 'Chargement',
			Navbar: 'Barre de navigation',
			Notification: 'Notification',
			Numberinput: 'Nombre dentrée',
			Pagination: 'Pagination',
			Popup: 'Apparaitre',
			Progress: 'Le progrès',
			Sidebar: 'Barre latérale',
			Slider: 'Glissière',
			Tabs: 'Onglets',
			Tooltip: 'Info-bulle',
			Forms: 'Formes',
			FormElements: 'FormElements',
			Checkboxes: 'Cases à cocher',
			Datepicker: 'Sélecteur de date',
			Input: 'Contribution',
			Radio: 'Radio',
			Select: 'Sélectionner',
			Switch: 'Commutateur',
			Textarea: 'Textarea',
			Upload: 'Télécharger',
			FormLayouts: 'Form Layouts',
			FormAction: 'Form Action',
			FormBasic: 'Form Basic',
			FormHorizontal: 'Form Horizontal',
			FormWizard: 'Form Wizard',
			DragnDrop: 'Drag n Drop',
			TableExamples: 'Table Exemples',
			Basictable: 'Basic table',
			Editabletable: 'Editable table',
			ExpandDatatable: 'Développer Datatable',
			Filtertable: 'Table de filtration',
			Miscellaneoustable: 'Miscellaneous table',
			Paginationtable: 'Table de pagination',
			Selectedtable: 'Table sélectionnée',
			SSTtable: 'SSTtable',
			Charts: 'Graphiques',
			ApexCharts: 'ApexCharts',
			ChartJs: 'ChartJs',
			Icons: 'Icônes',
			MaterialIcons: 'Icônes Matérielles',
			ThemifyIcons: 'ThemifyIcons',
			ExtraComponents: 'Extra Components',
			Carousel: 'Carrousel',
			Widgets: 'Widgets',
			AppWidgets: 'App Widgets',
			DataWidgets: 'DataWidgets'
		}
	
	},

})